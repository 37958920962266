import React from 'react';
import classes from './Social.module.scss';
import fb from '../../../assets/social/FB.png';
import ig from '../../../assets/social/IG.png';
import yt from '../../../assets/social/YT.png';


const images = [ig, fb, yt];


const Social = (props) => (
    <div className={classes.Container}>
        <div className={classes.Social}>
            <a href={'https://www.facebook.com/TheCannaCook'} className={classes.Square} rel='noreferrer' target='_blank' ><img src={images[1]} alt='' /></a>
            <a href={'https://www.instagram.com/thecannabiscook01/'}  rel='noreferrer' target='_blank' ><img src={images[0]} alt='' target='_blank' /></a>
        </div>
        <div className={classes.Subscribe}>
            <a href={'http://www.youtube.com/channel/UC627LnTjnTPFwITyWguF0tg?sub_confirmation=1'} rel='noreferrer' target='_blank' ><img src={images[2]} alt='' target='_blank' /></a>
        </div>
    </div>
    
    
);


export default Social;
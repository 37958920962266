import React from 'react';
import classes from './SideDrawer.module.scss';
import Aux from '../../../hoc/Auxiliary';
import NavigationItems from '../NavigationItems';
import BackDrop from '../Backdrop/Backdrop';


const SideDrawer = (props) => {

    let attachedClasses = [classes.SideDrawer, classes.Close]
    if (props.open) {
        attachedClasses = [classes.SideDrawer, classes.Open]
    }

    return(
        <Aux>
            <BackDrop show={props.open} clicked={props.closed} />
                <div className={attachedClasses.join(' ')}>
                    <nav>
                        <NavigationItems closing={props.closed} />
                    </nav>
                </div>
        </Aux>
        
    );
};


export default SideDrawer;
import React from 'react';
import classes from './NavigationItems.module.scss';
import NavigationItem from './NavigationItem/NavigationItem';


const NavigationItems = (props) => (
    <ul className={classes.NavigationItems} onClick={props.closing}>
        <NavigationItem link='/'>Vlog</NavigationItem>
        <NavigationItem link='/Store'>Store</NavigationItem>
        <NavigationItem link='/Calculator'>Calculator</NavigationItem>
        <NavigationItem link='/About'>About</NavigationItem>
    </ul>
);


export default NavigationItems;
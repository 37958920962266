import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Aux from '../../hoc/Auxiliary';
import classes from './Layout.module.scss';


//Components

import Youtube from '../youtube/Youtube';
import Calculator from '../calculator/Calculator';
import About from '../About/About';
import Store from '../Store/Store';

const Layout = () => (
    <Aux>
        <div className={classes.Layout}>
            <Switch>
                <Route path='/' exact component={Youtube} />
                <Route path='/Store' component={Store} />
                <Route path='/Calculator' component={Calculator} />
                <Route path='/About' component={About} />
            </Switch>
        </div>
    </Aux>
    
);
export default Layout;
import React, { Component } from 'react';
import classes from './Store.module.scss';
import StoreData from './Store.json';

const data = StoreData.items

class Store extends Component {
    render() {    
    return(
        <div className={classes.Store}>
            <h3>Welcome to the Store</h3>
            <ul>
                {
                    data.map((value) => {
                        return(
                        <li key={value.id}>
                            <div dangerouslySetInnerHTML={{__html: value.link}}></div>{/* {hyperLink.link} */}
                            <p className={classes.names}>{value.item}</p>
                        </li>)
                    })
                }
            </ul>
            <p className={classes.mention}>All items are directly linked to Amazon, thank you for the support.</p>
        </div>
    )}
};


export default Store;
import React from 'react';
import Linkify from 'react-linkify';
import classes from './Player.module.scss';

const Player = (props) => {

    //fetch description from Youtube video
    let textForm = props.desc;

    return(
        <div className={classes.pageLayout}>
            
                <div className={classes.videoContainer}>
                    <iframe title='Hello' width='853' height='480' src={`//www.youtube.com/embed/${props.video}?rel=0`} frameBorder="0" allowFullScreen></iframe>
                </div>
                <span className={classes.vTitle}>{props.title}</span>
                <div className={classes.description}>
                    <Linkify properties={{ target: '_blank' }}>
                        {textForm.split('\n').map((item, key) => {
                            return <span key={key}>{item}<br/></span>
                        })}
                    </Linkify>
                </div>
                
        </div>
        
    )
};

export default Player;
import React, { Component } from 'react';
import Aux from '../../hoc/Auxiliary';
import classes from './HeadComp.module.scss';
import imgBG from '../../assets/img/headBG.jpg';
import logoBG from '../../assets/img/logoBG.png';
import logo from '../../assets/img/logo.png';
import NavigationItems from '../NavigationItems/NavigationItems';
import SideDrawer from '../NavigationItems/SideDrawer/SideDrawer'
import BurgerButton from '../NavigationItems/SideDrawer/BurgerButton/BurgerButton';



class HeadComp extends Component {
    state = {
        showSideDrawer: false
    }

    sideDrawerClosedHandler = () => {
        this.setState({ showSideDrawer: false })
    }

    sideDrawerToggleHandler = () => {
        this.setState((prevState) => {
            return{showSideDrawer: !prevState.showSideDrawer};
        });
    }

    render() {
        return(
            <Aux>
                <div className={classes.Master}>
                    <div className={classes.HeadComp} style={{backgroundImage: `url(${imgBG})`,  backgroundRepeat: 'no-repeat', maxHeight: '150px',  width: '100%'}}>
                        <div className={classes.OverAll} style={{backgroundImage: `url(${logoBG})`,  backgroundRepeat: 'no-repeat', maxHeight: '100%',  width: '100%'}}>
                            <a href='/'><img className={classes.Logo} src={logo} alt='Logo TCC' /></a>
                            <div>
                                <BurgerButton clicked={this.sideDrawerToggleHandler} />
                                <SideDrawer open={this.state.showSideDrawer} closed={this.sideDrawerClosedHandler} />
                            </div>
                            <nav className={classes.DesktopOnly}>
                                <NavigationItems />
                            </nav>
                            
                        </div>
                    </div>
                </div>
            </Aux>
        );
    } 
    
};


export default HeadComp;
import React from 'react';
import classes from './BurgerButton.module.scss';


const BurgerButton = (props) => {
    return(
        <div className={classes.BurgerButton} onClick={props.clicked}>
            <div className={classes.Burg1} />
            <div className={classes.Burg2} />
            <div className={classes.Burg3} />
        </div>
    );
};


export default BurgerButton;
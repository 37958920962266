import React, {Component} from 'react';
import HeadComp from '../Components/HeadComp/HeadComp';
import Layout from '../Components/Layout/Layout';
import Footer from '../Components/Footer/Footer';


class App extends Component {
    
    
    render() {
        return(
            <div>
                <HeadComp />
                <Layout />    
                <Footer />
            </div>
            
        )
    }
};

export default App;

import React from 'react';
import classes from './Footer.module.scss';
import Social from './Social/Social';


const Footer = (props) => {
    return(
        <div className={classes.Footer}>
            <div className={classes.Left}></div>
            <div className={classes.Center}></div>
            <div className={classes.Right}>
                <Social />
            </div>
        </div>
    );
};


export default Footer;